import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../../composants/image/Image"
import LayoutTutorielEN from "../../../composants/layout/en/LayoutTutorielEN"
import Lien from "../../../composants/navigation/Lien"
import Section from "../../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageTraitementImagesVoieLactee: allPagesCompilationYaml(
      filter: { code: { eq: "traitementImagesVoieLacteeEN" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "photographierArcheVoieLacteeEN"
            "voieLacteeIntegralite360EN"
            "megalaxyZoomEN"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "voieLacteeIntegralite"
            "voieLacteePretraitementdesImagesDeVoieLacteeDansLigthroom"
            "voieLacteeSelectiondeLaVoieLacteeDansPhotoshop"
            "photoshopTraitementVoieLacteeFinal"
            "sequatorChargementEtOptions"
            "sequatorSelectionCielEtSol"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPortraitPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "voieLacteeVoieLacteeAvecDuBruit"
            "voieLacteeVoieLacteeSansBruit"
          ]
        }
      }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function ProcessingMilkyWayImages() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageTraitementImagesVoieLactee")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  let images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPortraitMoyen",
    "imagesPortraitPetit"
  )

  return (
    <LayoutTutorielEN
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Introduction">
        <p>
          This tutorial describes the processing of Milky Way nightscapes images
          with Photoshop, Lightroom and Sequator. If you never have photographed
          the Milky Way, I invite you to read{" "}
          <Lien codePageCible="photographierLaVoieLacteeEN">this article</Lien>{" "}
          first.
        </p>
        <p>
          The technique described here is especially useful if{" "}
          <strong>your sky is polluted</strong> or if{" "}
          <strong>your camera is not very sensitive</strong>. To follow this
          article, you must have taken a series of identical photos in order to
          reduce the noise of your Milky Way image.
        </p>
        <p>
          After a word about the ethics of the astrophotographer, we will
          proceed in 3 steps:
        </p>
        <ul>
          <li>Preprocessing in Lightroom.</li>
          <li>Processing in Sequator.</li>
          <li>Final aesthetic processing in Photoshop.</li>
        </ul>
        <Image
          image={obtenirImage(images, "voieLacteeIntegralite")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Ethics of the astrophotographer">
        <p>
          The technique described in this article raises an{" "}
          <strong>ethical problem</strong> for the astrophotographer.
        </p>
        <p>
          By mixing techniques of deep-sky astrophotography (stacking images in
          order to reduce the noise) and techniques of conventional photography,
          obtained results can be more like <strong>graphics</strong> than
          photography. Indeed, if the dosage is poorly realized, the result can
          frankly move away from the reality. Unfortunately, more and more
          astrophotographers push the vice by mixing a stacked Milky Way with a
          foreground from another image.
        </p>
        <p>
          These practices lead to a bad image of astrophotography with the
          public (they are “faked images”).
        </p>
        <p>To avoid that, please:</p>
        <ul>
          <li>
            Use the technique described in this article <strong>wisely</strong>{" "}
            (polluted sky, low sensitive DSLR, slow optics, artistic approach…).
          </li>
          <li>
            <strong>Be honest</strong> by specifying that your image is the
            result of stacked exposures.
          </li>
          <li>
            Do not use a <strong>foreground that is not located</strong> at the
            same place that your starry sky. Or specify it when you share your
            image.
          </li>
          <li>Do not stray from reality.</li>
          <li>
            Do not <strong>over-process</strong> your image: a simple processing
            is often the best.
          </li>
        </ul>
        <p>
          Personally, I now use very fast optics (eg a 24 mm f/1.4), under a
          beautiful starry sky. Which involves driving sometimes far from home…
        </p>
        <p>
          You can also wait for a small crescent of Moon illuminates your
          foreground…
        </p>
      </Section>
      <Section titre="Preprocessing of images">
        <p>
          In astrophotography, the purpose of the <strong>preprocessing</strong>{" "}
          is to create an image with{" "}
          <strong>the least defects as possible</strong> (noise, optical
          aberrations…) using a series of identical images. This image will be
          then used to for final aesthetic processing (colors, sharpness, etc.).
          This process is necessary because aesthetic processing can degrade
          very quickly an image, especially if it has been taken in darkness.
        </p>
      </Section>
      <Section titre="Preprocessing in Lightroom">
        <p>
          Locate the series of images you will use to create your final image.
          On one of your images, correct its luminosity and its optical problems
          (distortions and especially vignetting).
        </p>
        <p>
          <strong>ATTENTION</strong> : do not reduce noise at this step,
          otherwise you will lose the details of your picture. You will do it at
          the end of the process, once the aesthetic processing is realized.
        </p>
        <p>
          Then synchronize these settings with other images of your series (in
          “Library”: “Sync Param” at the bottom right). Once this done, export
          your images in TIFF format (do not use JPEG format).
        </p>
        <Image
          image={obtenirImage(
            images,
            "voieLacteePretraitementdesImagesDeVoieLacteeDansLigthroom"
          )}
          langue={page.langue}
        />
      </Section>
      <Section titre="In Sequator">
        <p>
          The individual exposures are now{" "}
          <strong>rid of their optical defects</strong> but they are always
          noisy. Hence we are going to stack them in order to reduce the noise
          and to get more possibilities during the final processing in
          Photoshop.
        </p>
        <p>
          Without diving into the details (reality is more complex), when
          stacking images <strong>you will simulate a longer exposure</strong>,
          so with more signal and less noise. With 3 images of 30 seconds, you
          will have a result similar to a 1 minute 30 seconds exposure. It will
          be even better because light pollution will be less, and if you do not
          have a tracking device, it will allow you to have punctual stars.
        </p>
        <p>I use the same principle in deep-sky astrophotography.</p>
        <p>The operations to be made are as follows:</p>
        <ul>
          <li>
            Download and install the software{" "}
            <Lien urlExterne="https://sites.google.com/site/sequatorglobal/">
              Sequator
            </Lien>{" "}
            (Windows only) that will allow you to stack your exposures. If you
            work with a Mac, you can use the software{" "}
            <Lien urlExterne="https://sites.google.com/site/starrylandscapestacker/home">
              Starry Landscape Stacker
            </Lien>
            .
          </li>
          <li>
            In the “Base image” section (1), select the first image of your
            series.
          </li>
          <li>
            In the “Star Images” section (2), select all images of your series.
          </li>
          <li>
            In the “Composition” section (3), choose the “Freeze ground” method.
          </li>
        </ul>
        <Image
          image={obtenirImage(images, "sequatorChargementEtOptions")}
          langue={page.langue}
        />
        <p>
          <strong>We will now tell Sequator where the starry sky is.</strong>{" "}
          For this, click on “Sky Region” (1). Choose the “Irregular Mask”
          option (2), then paint in green the region corresponding to the sky
          (3). If the brush to paint the starry sky in green is too large, use
          the “Ctrl + mouse wheel” shortcut.
        </p>
        <Image
          image={obtenirImage(images, "sequatorSelectionCielEtSol")}
          langue={page.langue}
        />
        <p>
          Now start the stack process by clicking on “Start” (4). You will then
          get an excellent quality “preprocessed” image: it has low noise and it
          is rid of a majority of your wide angle lens optical defects.
        </p>
        <p>Let’s compare the result before and after:</p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "voieLacteeVoieLacteeAvecDuBruit")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "voieLacteeVoieLacteeSansBruit")}
            langue={page.langue}
          />
        </div>
        <p>
          Even if the difference does not seem important, I assure you that it
          counts <strong>hugely</strong> to do a quality image processing.
          What’s more, there is only 1 minute and 30 seconds of total exposure
          time. Imagine the result with 5 minutes of total exposure time, it is
          even better!
        </p>
      </Section>
      <Section titre="Processing in Photoshop">
        <p>
          Your turn now! Open in Photoshop{" "}
          <strong>the result of the stacking process done with Sequator</strong>
          . Start by separating the foreground and the starry sky with a mask
          layer. Then, on the layer corresponding to the starry sky:
        </p>
        <ul>
          <li>
            Make a selection of the Milky Way using a 200-300 pixels progressive
            outline using the “Lasso” tool.
          </li>
          <li>Increase the brightness and contrast of the Milky Way.</li>
          <li>
            Invert the selection using the “Ctrl + Shift + I” keyboard shortcut.
            The selection contains now the starry sky without the Milky Way.
          </li>
          <li>
            Lower the brightness and increase the contrast of this selection.
          </li>
        </ul>
        <Image
          image={obtenirImage(
            images,
            "voieLacteeSelectiondeLaVoieLacteeDansPhotoshop"
          )}
          langue={page.langue}
        />
        <p>Next:</p>
        <ul>
          <li>
            Give a slight blue dominant to your image if necessary using the
            color balance adjustment tool.
          </li>
          <li>Increase exposure and saturation of your image.</li>
          <li>
            Perhaps add a little “artistic” vignetting (“Filter” menu then “Lens
            correction”) to focus the viewer’s attention on the Milky Way.
          </li>
          <li>
            Increase the brightness and saturation of the galactic bulb by
            selecting it using a lasso with an important progressive outline
            (200 pixels for example).
          </li>
          <li>
            Complete your work adding a slight touch of sharpness (“Filter” menu
            and “Optimized sharpness”).
          </li>
        </ul>
        <p>
          Finally, last tip, once your image is finished, do not share it
          immediately. Come back to it a few hours later. You will probably find
          some problems to correct…
        </p>
        <p>I manage to the following result:</p>
        <Image
          image={obtenirImage(images, "photoshopTraitementVoieLacteeFinal")}
          langue={page.langue}
        />
      </Section>
    </LayoutTutorielEN>
  )
}
